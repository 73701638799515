import React from "react";
import { useState } from "react";

//**Import projects from JSON */
import Projects from "../data/projects.json";

const projects = Projects.map((p, i) => {
  return p;
});

const createPills = (skills, projectName) => {
  let arr = [];

  skills.forEach((skill, index) => {
    let pillType = "";
    switch (skill) {
      case "Web Developer":
        pillType = "web-dev";
        break;
      case "Graphic Designer":
        pillType = "graphic-designer";
        break;
      case "UI/UX":
        pillType = "ui-ux";
        break;
    }

    arr.push(
      <span
        key={projectName + "" + skill + "_" + index}
        className={`pill-header ${pillType}`}
      >
        <p className="pill-text">{skill}</p>
      </span>
    );
  });

  return arr;
};

const SlideShow = ({ index, setIndex, setImage }) => {
  let project = projects[index];

  let pills = createPills(project.skills, project.name, index);

  setImage(project.img);

  return (
    <div className="project-slide">
      <div className="slide-content">
        <div className="pill-container">{pills}</div>

        <h2 className="project-title">{project.name}</h2>
        <p className="project-desc">{project.desc}</p>
      </div>
      <div className="slide-control-row">
        <div
          className="slide-control left-control"
          onClick={(e) => {
            setImage(project.img);
            setIndex(index - 1 === -1 ? projects.length - 1 : index - 1);
          }}
        >
          {"<"}
        </div>

        <div
          className="slide-control right-control"
          onClick={(e) => {
            setImage(project.img);
            setIndex(index + 1 === projects.length ? 0 : index + 1);
          }}
        >
          {">"}
        </div>
      </div>
    </div>
  );
};

const Portfolio = () => {
  const [index, setIndex] = useState(0);
  const [image, setImage] = useState("../images/ProfileImage.png");

  return (
    <div className="project-body">
      <div className="app-content project-content">
        <SlideShow
          index={index}
          setIndex={setIndex}
          setImage={setImage}
        ></SlideShow>
      </div>
      <div className="project-image-carousel">
        <img src={image} className="project-image"></img>
      </div>
    </div>
  );
};

export default Portfolio;
