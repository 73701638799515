import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/App.css";
import App from "./screens/App";
import reportWebVitals from "./reportWebVitals";
import logo from "./images/logo.png";
import Portfolio from "./screens/Portfolio";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <div className="app flex-col">
      <div className="app-container">
        <div className="app-navigation">
          <div>
            <img alt="NRD.Martinez Logo" className="app-logo" src={logo} />
          </div>
          <div className="nav-items">
            <a
              className="nav-link"
              rel="noreferrer"
              href="mailto:nrd.martinezz@gmail.com"
              target="_blank"
            >
              Email
            </a>
            <a
              className="nav-link"
              rel="noreferrer"
              href="sms:+19493352555?body=Hello!"
              target="_blank"
            >
              Text
            </a>
          </div>
        </div>

        <App />
      </div>
      <div className="app-container">
        <Portfolio />
      </div>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
